<template>
  <div>
    <main class="content-500">
      <section id="headerTitle">
        <h1>Widerrufsrecht</h1>

        <h2>Widerrufsrecht und Muster-Widerrufsformular</h2>

        <h3>Kein Widerrufsrecht für Eventtickets</h3>
        <p>
          Ein Widerrufsrecht für Verbraucher besteht nicht bzw. das Widerrufsrecht für Verbraucher kann vorzeitig erlöschen bei folgenden Verträgen:
        </p>
        <ul>
          <li>Verträge zur Erbringung von Dienstleistungen in den Bereichen Beherbergung zu anderen Zwecken als zu Wohnzwecken, Beförderung von Waren, Kraftfahrzeugvermietung, Lieferung von Speisen und Getränken sowie zur Erbringung weiterer Dienstleistungen im Zusammenhang mit Freizeitbetätigungen, wenn der Vertrag für die Erbringung einen spezifischen Termin oder Zeitraum vorsieht (§ 312g Abs. 2 Satz 1 Nr. 9 BGB).</li>
        </ul>
        <p>
          Das bedeutet, soweit die SMEA GmbH Dienstleistungen aus dem Bereich der Freizeitbetätigung anbietet, insbesondere Eintrittskarten für Veranstaltungen, besteht kein Widerrufsrecht. Jede Bestellung von Eintrittskarten ist damit unmittelbar nach Bestätigung durch die SMEA GmbH bindend und verpflichtet zur Abnahme und Bezahlung der bestellten Karten.
        </p>

        <h3>Widerrufsrecht für andere Waren</h3>
        <p>Für andere Waren oder Dienstleistungen, die nicht unter die oben genannten Ausnahmen fallen, gilt das folgende Widerrufsrecht:</p>

        <h4>Widerrufsbelehrung</h4>
        <p>
          <strong>Widerrufsrecht</strong><br>
          Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br><br>
          Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.
        </p>
        <p>
          Um Ihr Widerrufsrecht auszuüben, müssen Sie uns,
        </p>
        <p>
          SMEA GmbH<br>
          Kothöferdamm 7<br>
          30177 Hannover<br>
          Telefon: +49 511 12282957<br>
          E-Mail: <a href="mailto:dennis@smea.info">dennis@smea.info</a>
        </p>
        <p>
          mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
        </p>
        <p>
          Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
        </p>

        <h4>Folgen des Widerrufs</h4>
        <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
        </p>
        <p>
          Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
        </p>
        <p>
          Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit Ihnen zurückzuführen ist.
        </p>

        <h4>Muster-Widerrufsformular</h4>
        <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
        <p>
          ● An: SMEA GmbH, Kothöferdamm 7, 30177 Hannover<br>
          ● E-Mail: <a href="mailto:dennis@smea.info">dennis@smea.info</a><br>
          ● Hiermit widerrufe(n) ich/wir () den von mir/uns () abgeschlossenen Vertrag über den Kauf der folgenden Waren ()/die Erbringung der folgenden Dienstleistung ()<br>
          ● Bestellt am ()/erhalten am ()<br>
          ● Name des/der Verbraucher(s)<br>
          ● Anschrift des/der Verbraucher(s)<br>
          ● Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br>
          ● Datum<br>
          (*) Unzutreffendes streichen.
        </p>

      </section>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {notificationStore} from "@/store/notificationStore";
export default {
  name: "Widerrufsrecht",
  title: "Widerrufsrecht",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
main{
  padding-top: 80px;
}
section#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
  color: #000000;
}
section#headerTitle h2{
  font-family: 'Helvetica';
  font-size: 1.1em;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 4px;
}
section#headerTitle h3,
section#headerTitle h4{
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 4px;
}
p,
ul li{
  color: #00000080;
}
</style>