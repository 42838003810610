<template>
  <div>
    <main class="content-500">
      <section id="headerTitle">
        <h1>Datenschutz</h1>
        <p>
          Der Schutz Ihrer persönlichen Daten ist uns, der SMEA GmbH, Kothöferdamm 7, 30177 Hannover, ein wichtiges Anliegen. Wir möchten Sie darüber informieren, wie wir Ihre Daten verarbeiten, welche Rechte Sie in Bezug auf Ihre Daten haben und wie wir Ihre Privatsphäre schützen.
        </p>

        <h2>1. Verantwortlicher</h2>
        <p>
          Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist:<br>
          SMEA GmbH<br>
          Kothöferdamm 7, 30177 Hannover<br>
          E-Mail: <a href="mailto:kontakt@smea.info">kontakt@smea.info</a><br>
          Telefon: 0511 12282957
        </p>

        <h2>2. Welche Daten wir erheben</h2>
        <ul>
          <li><strong>Beim Besuch unserer Webseite:</strong>
            <ul>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Browsertyp und Betriebssystem</li>
              <li>Besuchte Seiten (Log-Daten)</li>
              <li>Referrer-URL</li>
            </ul>
          </li>
          <li><strong>Bei Nutzung des Kontaktformulars:</strong>
            <ul>
              <li>Name</li>
              <li>E-Mail-Adresse</li>
              <li>Telefonnummer (falls angegeben)</li>
              <li>Ihre Nachricht</li>
            </ul>
          </li>
          <li><strong>Bei Newsletter-Anmeldung:</strong>
            <ul>
              <li>Name</li>
              <li>E-Mail-Adresse</li>
              <li>Telefonnummer (falls angegeben)</li>
            </ul>
          </li>
          <li><strong>Bei Vertragsschluss oder Geschäftsanbahnung:</strong>
            <ul>
              <li>Name, Anschrift, Telefonnummer, E-Mail-Adresse</li>
              <li>Zahlungsdaten</li>
              <li>Vertragsinformationen</li>
            </ul>
          </li>
          <li><strong>Technische Daten durch Cookies und ähnliche Technologien:</strong>
            <ul>
              <li>Tracking-Informationen (siehe Abschnitt „Cookies und Tracking-Technologien“).</li>
            </ul>
          </li>
        </ul>

        <h2>3. Zweck und Rechtsgrundlage der Verarbeitung</h2>
        <p>
          Ihre personenbezogenen Daten werden zu folgenden Zwecken verarbeitet:
        </p>
        <ul>
          <li>Erbringung unserer Dienstleistungen (Art. 6 Abs. 1 lit. b DSGVO): Zur Durchführung vorvertraglicher Maßnahmen oder zur Vertragserfüllung.</li>
          <li>Betrieb und Sicherheit der Webseite (Art. 6 Abs. 1 lit. f DSGVO): Zur Analyse, Optimierung und Absicherung unseres Online-Angebots.</li>
          <li>Kommunikation (Art. 6 Abs. 1 lit. a DSGVO): Um Anfragen über das Kontaktformular oder per E-Mail zu beantworten (auf Basis Ihrer Einwilligung).</li>
          <li>Marketingzwecke (Art. 6 Abs. 1 lit. f DSGVO): Für die Versendung unseres Newsletters (nur mit Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO).</li>
        </ul>

        <h2>4. Weitergabe von Daten an Dritte</h2>
        <p>
          Wir geben Ihre Daten nur weiter, wenn:
        </p>
        <ul>
          <li>Sie ausdrücklich eingewilligt haben (Art. 6 Abs. 1 lit. a DSGVO),</li>
          <li>dies zur Erfüllung eines Vertrags notwendig ist (Art. 6 Abs. 1 lit. b DSGVO),</li>
          <li>wir gesetzlich dazu verpflichtet sind (Art. 6 Abs. 1 lit. c DSGVO), oder</li>
          <li>dies zur Wahrung berechtigter Interessen erforderlich ist (Art. 6 Abs. 1 lit. f DSGVO).</li>
        </ul>
        <p>
          Empfänger können sein:
        </p>
        <ul>
          <li>IT-Dienstleister, die unsere Webseite betreiben,</li>
          <li>Zahlungsdienstleister für die Abwicklung von Zahlungen,</li>
          <li>Marketing- und Analysepartner.</li>
        </ul>
        <p>
          Eine Übermittlung in Drittländer erfolgt nur, wenn geeignete Garantien gem. Art. 44 ff. DSGVO bestehen (z. B. EU-Standardvertragsklauseln).
        </p>

        <h2>5. Cookies und Tracking-Technologien</h2>
        <p>
          Unsere Webseite verwendet Cookies. Diese dienen dazu, unsere Webseite nutzerfreundlicher zu gestalten und Ihre Präferenzen zu speichern.
        </p>
        <ul>
          <li>Notwendige Cookies: Diese sind für den Betrieb der Webseite unerlässlich.</li>
          <li>Analyse-/Marketing-Cookies: Diese helfen uns, die Nutzung unserer Webseite zu analysieren.</li>
        </ul>
        <p>
          Sie können Ihre Cookie-Einstellungen jederzeit ändern oder Cookies über Ihre Browser-Einstellungen deaktivieren.
        </p>

        <h2>6. Ihre Rechte</h2>
        <ul>
          <li><strong>Auskunft:</strong> Sie können eine Bestätigung verlangen, ob und welche Daten wir über Sie verarbeiten (Art. 15 DSGVO).</li>
          <li><strong>Berichtigung:</strong> Sie haben das Recht auf Berichtigung unrichtiger oder unvollständiger Daten (Art. 16 DSGVO).</li>
          <li><strong>Löschung:</strong> Sie können die Löschung Ihrer Daten verlangen, sofern kein rechtlicher Grund zur weiteren Verarbeitung besteht (Art. 17 DSGVO).</li>
          <li><strong>Einschränkung:</strong> Sie können die Einschränkung der Verarbeitung verlangen (Art. 18 DSGVO).</li>
          <li><strong>Datenübertragbarkeit:</strong> Sie können verlangen, dass wir Ihnen Ihre Daten in einem maschinenlesbaren Format zur Verfügung stellen (Art. 20 DSGVO).</li>
          <li><strong>Widerspruch:</strong> Sie können der Verarbeitung Ihrer Daten aus Gründen, die sich aus Ihrer besonderen Situation ergeben, widersprechen (Art. 21 DSGVO).</li>
        </ul>
        <p>
          Zur Ausübung Ihrer Rechte wenden Sie sich bitte an: <a href="mailto:datenschutz@smea-gmbh.de">datenschutz@smea-gmbh.de</a>.
        </p>

        <h2>7. Speicherdauer</h2>
        <p>
          Wir speichern Ihre Daten nur so lange, wie dies für die jeweiligen Zwecke erforderlich ist oder wir gesetzlich dazu verpflichtet sind.
        </p>

        <h2>8. Sicherheitsmaßnahmen</h2>
        <p>
          Wir setzen technische und organisatorische Maßnahmen ein, um Ihre Daten gegen Verlust, Missbrauch und unbefugten Zugriff zu schützen.
        </p>

        <h2>9. Änderungen dieser Datenschutzhinweise</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzhinweise anzupassen. Die jeweils aktuelle Version finden Sie auf unserer Webseite.
        </p>

        <h2>10. Beschwerden bei der Aufsichtsbehörde</h2>
        <p>
          Wenn Sie der Ansicht sind, dass wir Ihre Daten unrechtmäßig verarbeiten, können Sie eine Beschwerde bei der zuständigen Datenschutzbehörde einreichen. Zuständig für die SMEA GmbH ist:
        </p>
        <p>
          Die Landesbeauftragte für den Datenschutz Niedersachsen<br>
          Prinzenstraße 5<br>
          30159 Hannover<br>
          E-Mail: <a href="mailto:poststelle@lfd.niedersachsen.de">poststelle@lfd.niedersachsen.de</a>
        </p>

      </section>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {notificationStore} from "@/store/notificationStore";
export default {
  name: "Datenschutz",
  title: "Datenschutz",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
main{
  padding-top: 80px;
}
section#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
  color: #000000;
}
section#headerTitle h2{
  font-family: 'Helvetica';
  font-size: 1.1em;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 4px;
}
section#headerTitle h3,
section#headerTitle h4{
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 4px;
}
p,
ul li{
  color: #00000080;
}
</style>