<template>
  <div>
    <main class="content-500">
      <section id="headerTitle">
        <h1>AGB</h1>
        <p>
          Die SMEA GmbH (nachfolgend SMEA genannt) ist nicht selbst Veranstalter der angebotenen Veranstaltungen. Diese werden durch den jeweiligen Veranstalter durchgeführt, der auch Aussteller der Tickets ist. Durch den Erwerb der Eintrittskarte kommen vertragliche Beziehungen im Hinblick auf den Veranstaltungsbesuch ausschließlich zwischen dem Karteninhaber (Kunden) und dem jeweiligen Veranstalter zustande. Möglicherweise gelten für diese rechtlichen Beziehungen eigene Allgemeine Geschäftsbedingungen des Veranstalters. SMEA vertreibt die Tickets im Auftrag des jeweiligen Veranstalters als Vermittlerin oder als Kommissionärin, es sei denn, sie ist im Einzelfall ausdrücklich selbst als Veranstalter ausgewiesen. Mit der Bestellung von Tickets beauftragt der Kunde SMEA mit der Abwicklung des Kartenkaufs einschließlich Versand.
        </p>

        <h2>Themenübersicht</h2>
        <ol>
          <li>Erstellung und Nutzung des Kunden-Accounts</li>
          <li>Geltungsbereich</li>
          <li>Vertragsabschluss, Stornierung</li>
          <li>Preisbestandteile & Zahlungsmodalitäten</li>
          <li>Widerrufsrecht und Muster-Widerrufsformular</li>
          <li>Eigentumsvorbehalt; Aufrechnung; Zurückbehaltungsrecht beim Rechnungskauf</li>
          <li>Haftungsbeschränkungen</li>
          <li>Schlussklauseln</li>
        </ol>

        <h2>I. Erstellung und Nutzung des Kunden-Accounts</h2>
        <p>
          Kunden können durch entsprechende Registrierung einen Kunden-Account anlegen. Über den Kunden-Account können Kunden, grundsätzlich unabhängig von einem Ticketkauf, beispielsweise unter „Mein SMEA“ Newsletter verschiedener Genres und Ticketalarme für Lieblingsveranstaltungen verwalten, sich für Treueaktionen anmelden, persönliche Daten ändern sowie getätigte Bestellungen einsehen.
        </p>
        <p>
          Durch Zusendung der entsprechenden Bestätigungs-E-Mail nach Abschluss der Registrierung wird eine Nutzungsvereinbarung betreffend den Kunden-Account zwischen dem Kunden und SMEA geschlossen.
        </p>
        <p>
          Die bei der Registrierung angegebenen Zugangsdaten, insbesondere das Passwort, müssen durch den Kunden sorgfältig verwahrt werden. Das Passwort darf nicht an Dritte weitergegeben werden. Erlangt ein Kunde Kenntnis von dem Missbrauch seiner Zugangsdaten, so hat er SMEA unverzüglich darüber zu informieren.
        </p>
        <p>
          SMEA stellt den jeweiligen Kunden-Account kostenlos bereit; die Nutzung ist freiwillig. Informationen hinsichtlich der den Kunden-Account betreffenden Verarbeitungen von personenbezogenen Daten werden in der Datenschutzinformation bereitgehalten.
        </p>
        <p>
          Der Kunde kann die Nutzungsvereinbarung ohne Kündigungsfrist mit sofortiger Wirkung durch Mitteilung an den SMEA-Kundenservice beenden, mit der Folge, dass der Kunde keinen Zugriff mehr auf die im Kunden-Account gespeicherten Daten hat. SMEA empfiehlt, die Nutzungsvereinbarung nicht zu kündigen, solange der Kunde den Kunden-Account noch für laufende Verträge oder Ansprüche benötigt. Beispielsweise ist ein nutzbarer Kunden-Account zwingende Voraussetzung für spezielle Zutrittsberechtigungen. Kündigt der Kunde dennoch, wird sich SMEA bemühen, den Kunden über die Folgen zu informieren.
        </p>
        <p>
          SMEA behält sich im Falle der Kündigung durch den Kunden vor, den Kunden-Account so lange aktiv zu lassen, bis sämtliche wechselseitigen Ansprüche zwischen Kunde und SMEA vollumfänglich erfüllt sind. Jedoch übernimmt SMEA keine Verantwortung dafür, falls ein Kunde aufgrund seiner Kündigung bezahlte Leistungen nicht in Anspruch nehmen kann.
        </p>
        <p>
          SMEA behält sich das Recht vor, den Kunden-Account vorübergehend zu sperren, insbesondere wenn dieser zu betrügerischen oder sonstigen rechtswidrigen Zwecken oder durch unbefugte Dritte missbraucht wird. SMEA hat im Falle der missbräuchlichen Nutzung des Kunden-Accounts zudem das Recht, die Nutzungsvereinbarung mit einer Frist von fünf Kalendertagen zu kündigen.
        </p>
        <p>
          Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt.
        </p>

        <h2>II. Geltungsbereich</h2>
        <p>
          Für sämtliche Verträge und erteilten Aufträge betreffend die Lieferung von Tickets, Gutscheinen und/oder Fan-Artikeln (bspw. Bild- und Tonträger) gelten im Verhältnis zu SMEA ausschließlich die nachfolgenden Allgemeinen Geschäftsbedingungen.
        </p>
        <p>
          Für sämtliche Verträge und Aufträge betreffend die Vermittlung oder Durchführung von Reisen gelten die Allgemeinen Reisebedingungen, soweit SMEA diese ausweislich der jeweiligen Rechnung im eigenen Namen und auf eigene Rechnung verkauft sowie die Allgemeinen Geschäftsbedingungen des jeweiligen Reiseveranstalters. Diese findet der Kunde jeweils durch die direkt im Angebot befindlichen Verweise.
        </p>

        <h2>III. Vertragsabschluss, Stornierung</h2>
        <p>
          Das Angebot für einen Vertragsabschluss geht vom Kunden aus, sobald er den sog. „Kaufen-Button“ bzw. die entsprechend § 312j Abs. 3 BGB eindeutig beschriftete Schaltfläche angeklickt hat. Erst mit Zuteilung und Übersendung der Transaktionsnummer/Bestellnummer/Ordernummer durch SMEA an den Kunden kommt ein Vertrag zwischen dem Kunden und dem jeweiligen Vertragspartner (Veranstalter, lizenzierter Fan-Artikel-Verkäufer oder SMEA) zustande. Hiervon abweichend kommt bei der Zahlungsart Vorkasse der Vertrag mit erfolgreicher Belastung des Zahlungskontos des Kunden mit dem vollständigen Zahlungsbetrag zustande.
        </p>
        <p>
          Soweit beim Kauf nicht anderweitig vereinbart, ist ein gewerblicher Weiterverkauf der Eintrittskarte(n) nicht gestattet, ein nichtgewerblicher Weiterverkauf nur zu einem Preis in Höhe des aufgedruckten Preises der Eintrittskarte(n) zuzüglich beim ursprünglichen Kauf angefallener nachgewiesener Gebühren/Kosten (z. B. Porto- und Versandkosten), maximal jedoch in Höhe von 25 % des aufgedruckten Preises. Beim Verstoß gegen die vorgenannte Bedingung hat der jeweilige Veranstalter das Recht, den Zutritt zu der Veranstaltung ersatzlos zu verweigern.
        </p>

        <h2>IV. Preisbestandteile & Zahlungsmodalitäten</h2>
        <p>
          Die Preise für Tickets können die aufgedruckten Kartenpreise übersteigen. Folgende Zahlungsarten werden angeboten:
        </p>
        <ul>
          <li>SEPA-Lastschriftverfahren</li>
          <li>Kreditkarte</li>
          <li>giropay</li>
          <li>Sofortüberweisung</li>
          <li>Vorkasse</li>
          <li>Kauf auf Rechnung oder Ratenzahlung (sofern verfügbar und Bonitätsprüfung erfolgreich)</li>
        </ul>
        <p>
          Die gesetzliche Mehrwertsteuer ist im Preis enthalten. Alle Gebühren werden im Warenkorb ausgewiesen. Die Zahlung ist je nach Zahlungsart entweder sofort nach Vertragsabschluss oder bei Vorkasse bis zum mitgeteilten Datum fällig. Zusätzliche Gebühren können für Serviceleistungen oder Versandkosten entstehen, die bei der Bestellung angezeigt werden.
        </p>

        <h2>V. Eigentumsvorbehalt</h2>
        <p>
          Die Ware bleibt bis zur vollständigen Zahlung Eigentum der SMEA GmbH. Bei Zahlungsverzug des Kunden werden sämtliche offenen Forderungen sofort fällig.
        </p>

        <h2>VI. Schlussklauseln</h2>
        <p>
          Es gilt das Recht der Bundesrepublik Deutschland. Gerichtsstand für alle Streitigkeiten ist Hannover, sofern der Kunde Kaufmann i.S.d. § 14 BGB ist.
        </p>
      </section>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {notificationStore} from "@/store/notificationStore";
export default {
  name: "AGB",
  title: "AGB",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
    }
  },
  computed: {
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
main{
  padding-top: 80px;
}
section#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
  color: #000000;
}
section#headerTitle h2{
  font-family: 'Helvetica';
  font-size: 1.1em;
  font-weight: bold;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 4px;
}
section#headerTitle h3,
section#headerTitle h4{
  font-family: 'Helvetica';
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 4px;
}
p,
ol li,
ul li{
  color: #00000080;
}
</style>