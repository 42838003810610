<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12 pad-8" style="padding-top: 6px!important;">
        <svg v-if="!config.menuModal" @click="config.menuModal = true; supportModal = false;" width="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="menu " id="menu_"><path d="M29,6H3A1,1,0,0,0,3,8H29a1,1,0,0,0,0-2Z"/><path d="M3,17H16a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z"/><path d="M25,24H3a1,1,0,0,0,0,2H25a1,1,0,0,0,0-2Z"/></g></svg>
        <svg v-else @click="config.menuModal = false" width="32px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>
      </div>
      <div class="col-sp-6 row-sp-12 t-center pad-8">
        <router-link to="/home" @click="config.menuModal = false">
          <img src="assets/images/partyticket-logo.png" class="width-200">
        </router-link>
      </div>
      <div class="col-sp-3 row-sp-12 pad-8 t-right">
        <router-link id="notificationBell" to="/notifications" @click="readNotifications()">
          <svg width="24px" style="vertical-align: top; margin: 4px 8px 0 0;" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g id="bell"><path d="M29.78,24.37l-3.65-4.48L26,12a1,1,0,0,0,0-.16C24.85,5.58,21.21,2,16,2,10.5,2,6.08,6,5,11.82A1,1,0,0,0,5,12l-.13,8L2.14,24.48a1,1,0,0,0,0,1A1,1,0,0,0,3,26h9a4,4,0,0,0,8,0h9a1,1,0,0,0,.9-.57A1,1,0,0,0,29.78,24.37ZM16,28a2,2,0,0,1-2-2h4A2,2,0,0,1,16,28ZM4.77,24l2-3.24a1,1,0,0,0,.14-.5L7,12.1C7.91,7.25,11.52,4,16,4c5.63,0,7.43,5,8,8.1l.14,8.16a1,1,0,0,0,.22.62L26.9,24Z"/></g></svg>
          <span v-if="notification.unread > 0 && auth.isAuth">{{ notification.unread }}</span>
        </router-link>
      </div>
    </div>
  </header>
  <transition name="modal" id="MenuModal" style="position: fixed; padding: 16px;">
    <div class="vueModal" v-if="config.menuModal">
      <div class="vueModalOuter">
        <div class="menuContent" style="max-height: calc(100% - 160px);overflow-y: scroll;">
          <ul id="navTop">
            <li>
              <router-link to="/home" @click="config.menuModal = false">
                🎉
                Veranstaltungen
              </router-link>
            </li>
            <li>
              <router-link to="/tickets" @click="config.menuModal = false">
                🎟️
                Meine Tickets
              </router-link>
            </li>
            <li>
              <router-link to="/coupons" @click="config.menuModal = false">
                🎁
                Gutscheine
              </router-link>
            </li>
            <li>
              <router-link to="/faq" @click="config.menuModal = false">
                ❓
                Hilfe / FAQ
              </router-link>
            </li>
            <li>
              <router-link to="/muttizettel" @click="config.menuModal = false">
                📝
                Muttizettel
              </router-link>
            </li>
            <li>
              <a href="https://nachtaktivevents.de/" style="cursor: pointer;">
                🔗
                Über uns
              </a>
            </li>
            <div class="pad-8"></div>
            <li>
              <router-link to="/settings" @click="config.menuModal = false">
                ⚙️
                Einstellungen
              </router-link>
            </li>

          </ul>
          <div class="footer">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12">
                <a class="color-blue" href="/impressum/" target="_blank">Impressum</a>
              </div>
              <div class="col-sp-12 row-sp-12">
                <a class="color-blue" href="/datenschutz/" target="_blank">Datenschutz</a>
              </div>
              <div class="col-sp-12 row-sp-12">
                <a class="color-blue" href="/widerrufsrecht/" target="_blank">Widerrufsrecht</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: "NavBar",
  components: {
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data() {
    return {
      supportModal: false,
      notificationCount: 0,
      navCityItems: '',
    }
  },
  mounted() {
    this.getNotificationsCount()
    this.getMyCities()
  },
  methods:{
    getNotificationsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotificationsCount', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = response.data.length
            }
          })
    },
    readNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = 0
            }
          })
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 6)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.navCityItems = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  background-color: var(--second-bg-color);
  padding-top: calc(8px + env(safe-area-inset-top));
}
header img.image{
  border: 2px solid #ffffff;
}
header svg{
  cursor: pointer;
}
#MenuModal{
  top: 40px;
  height: 100%;
  background: #ffffff;
  z-index: 60;
  padding-top: calc(16px + env(safe-area-inset-top)) !important;
}
#MenuModal .menuContent{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding-top: 40px;
}
#MenuModal .menuContent #navTop{
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255,255,255,0.6);
}
#MenuModal .menuContent #navTop li{
  list-style: none;
  display: block;
  margin-bottom: 12px;
}
#MenuModal .menuContent #navTop li a{
  text-decoration: none;
  display: block;
  color: #000000;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.3em;
  transition: 0.3s;
}
#MenuModal .menuContent #navTop li a button{
  float: right;
  margin-top: -4px;
  margin-left: 8px;
}
#MenuModal .menuContent #navTop li a:hover{
  color: #2014ff;
}
#MenuModal .menuContent .locations h4{
  margin-top: 16px;
  font-size: 1.3em;
  color: #2014ff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
#MenuModal .menuContent .locations{
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255,255,255,0.6);
  padding-bottom: 16px;
}
#MenuModal .menuContent .locations li{
  list-style: none;
  display: inline-block;
  margin-bottom: 4px;
  width: 50%;
}
#MenuModal .menuContent .locations li a{
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  transition: 0.3s;
}
#MenuModal .menuContent .locations li a:hover{
  color: #2014ff;
}
#MenuModal .menuContent .footer{
  padding-top: 16px;
}
#MenuModal .menuContent .footer a{
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  color: #ffffff;
  font-size: 0.9em;
  margin-bottom: 8px;
  transition: 0.3s;
}
#MenuModal .menuContent .footer a:hover{
  color: #2014ff;
}
#notificationBell {
  position: relative;
}
#notificationBell span{
  position: absolute;
  background-color: #2014ff;
  color: #ffffff;
  z-index: 3;
  left: 8px;
  top: 0px;
  border-radius: 90%;
  width: 12px;
  height: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  padding: 4px;

}
</style>